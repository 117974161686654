export const style = `.invoice-infos {
    display: flex;
    flex-direction: row; 
    justify-content: space-between;
    align-items: center;
  }
  
  .invoice-infos > * {
    display: flex;
    flex-direction: column;
    width: 20%;
  }
  
  .invoice-infos .left > :first-child {
    font-weight: 500;
    font-size: 13px;
    line-height: 20px;
    color: #9da8ba;
  }
  
  .invoice-infos .left > :nth-child(2) {
    font-weight: 500;
    font-size: 19px; 
    line-height: 28px;
  }
  
  .invoice-infos .left > :nth-child(3) {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #5d6e82;
  }
  
  .invoice-infos .left > :nth-child(4), 
  .invoice-infos .left > :nth-child(5) {
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #2196f3;
  }
  
  .invoice-infos .right {
    font-weight: 600;
    font-size: 13px;
    line-height: 17px;
    color: #5d6e82;
    display: flex;
    flex-direction: column;
    gap: 0.25rem;
  }
  
  .invoice-infos .right > * {
    display: flex;
    justify-content: space-between;
    padding: 2px;
  }
  
  .invoice-infos .right > * span {
    flex: 0 0 50%;
    font-weight: 400;
    text-align: left;
  }
  
  .invoice-infos .right > :last-child {
    color: #00864e;
  }
  
  .invoice-infos .right > :last-child span {
    font-weight: 700;
  }
  
  .invoice-infos .right > :last-child {
    background: #e0faef;
  }
  
  .invoice-table {
    display: flex;
    flex-direction: column;
    align-items: end; 
  }
  
  .invoice-table table {
    width: 100%;
  }
  
  .invoice-table table > :nth-child(odd) {
    background: #f9fafd;
  }
  
  .invoice-table table tr {
    font-weight: 500;
    font-size: 16px;
    line-height: 20px;
    padding: 1rem;
    color: #344050;  
    border: solid 1px red;
  }
  
  .invoice-table table tr td {
    padding: 0.5rem 1rem;
    text-align: end;
    font-weight: 400;
    font-size: 13px;
    line-height: 17px;
    color: #5d6e82;
    border: solid 1px #dddeee;
  }
  
  .invoice-table table tr > :first-child {
    text-align: start;
    font-weight: 500;
    font-size: 16px;
    line-height: 20px; 
  }
  
  .invoice-table table > :first-child {
    background: #2196f3;
    color: #fff;
  }
  
  .invoice-table table > :first-child th {
    text-align: center;
    padding: 0.5rem;
  }
  
  .invoice-table .total {
    width: 20%;
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    font-weight: 600;
    font-size: 13px;
    line-height: 17px; 
  }
  
  .invoice-table .total > * {
    display: flex;
    justify-content: space-between;
    padding: 0.25rem;
  }
  
  .invoice-table .total > * span {
    color: #5d6e82;
    text-align: end;
  }
  
  .invoice-table .total > :nth-child(3) {
    position: relative;
  }
  
  .invoice-table .total > :nth-child(3)::after, 
  .invoice-table .total > :nth-child(3)::before {
    content: "";
    display: flex;
    width: 100%;
    height: 1px;
    background: #d8e2ee;
    position: absolute;
  }
  
  .invoice-table .total > :nth-child(3)::after {
    bottom: 0;
  }
  
  .invoice-table .total > :nth-child(3)::before {
    top: 0;
  }
  
  .invoice-table .total :last-child span {
    font-weight: 700;
  }`;
